@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

:root {
  --green-accent: rgba(30, 215, 96, 1);
  --green-text-color: #29523a;
  --yellow-color: #f8c200;
  --light-gray: #37474F;
  --dark-gray: #20232f;
  --facebook-color: #6c8bce;
  --twitter-color: #00ACEE;
  --youtube-color: #C4302B;
  --linkedin-color: #0E76A8;
  --instagram-color: #d6249f;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Rubik', sans-serif !important;
}

.topbar-height>.uk-navbar-item {
  min-height: 50px !important;
  height: 50px !important;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  color: #fff !important;
}

.topbar-buttons {
  background: rgba(248, 195, 0, 0.75) !important;
  color: #fff !important;
}

.topbar-buttons:hover {
  background: #20232f;
  color: #fff !important;
}

.uk-background-topbar {
  background: rgb(55, 71, 79) !important;
  border-bottom: 1px solid #1ed760;
  color: #fff !important;
}

.uk-text-white {
  color: #fff !important;
}

.uk-background-main-menu {
  margin: 0;
  padding: 10px 0 10px 0;
  background-image: url(./images/bg_pattern.png), linear-gradient(135deg, rgba(2, 40, 88, 1) 0%, rgba(78, 165, 114, 1) 100%);
  background-blend-mode: soft-light;
  box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
}

.uk-active {
  color: #f8c200 !important;
}

.uk-background-submenu {
  background: #263238 !important;
  color: #fff;
  width: auto !important;
}

.uk-navbar-dropdown-nav>li {
  padding: .5rem 1.5rem;
  border-radius: 4px;
}

.uk-navbar-dropdown-nav>li.uk-active>a {
  color: #f8c200 !important;
}

.uk-navbar-dropdown-nav>li:focus,
.uk-navbar-dropdown-nav>li:hover {
  background: #324047 !important;
}

.carousel-inner:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(41, 82, 58, 0.8) 0%, rgba(51, 85, 86, 0.8) 52%, rgba(56, 86, 98, 0.8) 75%);
}

.carousel-h1 {
  white-space: normal;
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: rgb(248, 195, 0);
  text-transform: uppercase;
}

.carousel-p {
  white-space: normal;
  font-size: 45px;
  line-height: 55px;
  font-weight: 300;
  color: rgb(255, 255, 255);
}

.uk-banner-icons {
  margin-right: 1.725rem !important;
  color: #fff !important;
}

.uk-margin-homeslide {
  margin-left: 20% !important;
  margin-top: -150px !important;
}

.uk-border-rounded-more {
  background-color: #f8c200 !important;
  border-radius: 50px !important;
  color: #000 !important;
  white-space: nowrap;
  font-size: 17px !important;
  line-height: 55px !important;
}

.uk-text-meta-fd {
  font-size: 24px !important;
}

.uk-overlay-fd {
  background-color: rgba(55, 71, 79, .5) !important;
  color: #fff !important;
  backdrop-filter: blur(15px);
}

.uk-checkbox,
.uk-radio {
  border: 1px solid #fff !important;
}

.uk-checkbox:checked,
.uk-checkbox:indeterminate,
.uk-radio:checked {
  background-color: #f8c200 !important;
  border-color: transparent;
}

.uk-navbar-dropdown-nav {
  font-size: 15px !important;
  text-transform: capitalize !important;
  font-weight: 300 !important;
}

.uk-card-primary.uk-card-body .uk-navbar-nav>li>a,
.uk-card-primary>:not([class*=uk-card-media]) .uk-navbar-nav>li>a,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li>a,
.uk-card-secondary>:not([class*=uk-card-media]) .uk-navbar-nav>li>a,
.uk-light .uk-navbar-nav>li>a,
.uk-offcanvas-bar .uk-navbar-nav>li>a,
.uk-overlay-primary .uk-navbar-nav>li>a,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a {
  color: #fff !important;
}

.uk-navbar-dropdown-nav>li>a {
  color: #fff !important;
}

.soluciones-background {
  background-image: url(./images/formas-digitales-fondo-banner-soluciones-en-facturacion.png);
  background-size: cover;
  background-position: center;
  height: 700px !important;
}

.green-text {
  color: var(--green-text-color) !important;
}

.green-background {
  background-color: var(--green-text-color) !important;
}

#offcanvas-menu>.uk-offcanvas-bar {
  background: rgb(38, 50, 56) !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .5);
  /*border-right: 1px solid #1ed760 !important;*/
}

.uk-offcanvas-overlay::before {
  background: rgba(0, 0, 0, .5) !important;
}

.uk-card-gradient:hover {
  background-image: linear-gradient(45deg, #4ea572 0%, #022858 70%) !important;
  color: #fff !important;
  box-shadow: 0px 10px 15px -5px #4ea572, 0px 0px 15px -10px #022858, 0px 5px 27px -10px #022858, 0px 0px 17px -15px #000000 !important;
}

.appLogos {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 192px;
  height: 92px;
  margin: auto;
}

.section-header {
  font-weight: 700;
  color: #fff !important;
  border-bottom-color: #f8c200 !important;
}

.uk-background-gradient {
  background-image: linear-gradient(45deg, #4ea572 0%, #022858 70%) !important;
}

.uk-toptop-fd {
  position: fixed !important;
  margin: auto !important;
  bottom: 55px;
  right: 50px;
  z-index: 9999 !important;
  background-color: rgba(29, 39, 44, 1) !important;
  color: #fff !important;
  width: 50px !important;
  height: 50px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.uk-toptop-fd:hover {
  background-color: rgb(47, 61, 68) !important;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.uk-line-height-small {
  line-height: 20px !important;
}

#mainMenu a:hover,
.uk-footer-fd .uk-light a:hover {
  color: rgba(248, 195, 0, 1) !important;
}

.uk-footer-fd {
  background: rgb(55, 71, 79) !important;
}

li a:hover .facebook {
  color: var(--facebook-color) !important;
}

li a:hover .twitter {
  color: var(--twitter-color) !important;
}

li a:hover .youtube {
  color: var(--youtube-color) !important;
}

li a:hover .linkedin {
  color: var(--linkedin-color) !important;
}

li a:hover .instagram {
  color: var(--instagram-color)
}

.uk-heading-bullet {
  color: var(--green-text-color) !important;
}

.uk-heading-bullet::before {
  border-left: calc(5px + .1em) solid var(--yellow-color) !important;
}

#map {
  height: 400px;
  width: 100%;
}

.nosotros-bg {
  background: rgb(235, 234, 234) !important;
  background: linear-gradient(145deg, rgba(235, 234, 234, 1) 0%, rgba(235, 234, 234, 1) 49%, rgba(255, 255, 255, 1) 49%, rgba(255, 255, 255, 1) 100%) !important;
}

.uk-overlay-formas {
  background-color: rgba(55, 71, 79, .5);
}

.uk-card-vision {
  transition: all .3s ease-in-out;
}

.uk-card-vision:hover {
  background-color: rgba(21, 163, 72, .25);
  transition: all .3s ease-in-out;
  border-radius: 8px;
}

.uk-table {
  padding: 1rem !important;
  text-align: left;
  vertical-align: bottom;
  font-size: 1rem;
  font-weight: 400;
  color: #999;
}

.uk-table th {
  background-color: rgba(41, 82, 58, 0.8);
  color: #fff !important;
  padding: 1rem !important;
}

.uk-table tbody tr td {
  padding: 1rem !important;
}

.uk-table-hover tbody tr:hover,
.uk-table-hover>tr:hover {
  background: rgba(55, 71, 79, .05) !important;
  color: #333 !important;
}

.uk-subnav-pill>*>:first-child {
  padding: 1rem !important;
  background: 0 0;
  color: #999;
}

.uk-subnav-pill>.uk-active>a {
  background-image: linear-gradient(45deg, #4ea572 0%, #022858 70%) !important;
  color: #fff !important;
  font-weight: 500;
  padding: 1rem;
  border-radius: 8px;
}

.uk-subnav-pill>*>a:focus,
.uk-subnav-pill>*>a:hover {
  background-color: #f8f8f8;
  border-radius: 8px;
}

.uk-link-fd {
  color: var(--yellow-color) !important;
  text-decoration: none;
  font-weight: 500;
}

.uk-link-fd:hover {
  text-decoration: none;
}

.bg-recomienda {
  background-image: linear-gradient(90deg, rgba(41, 82, 58, 0.8) 0%, rgba(51, 85, 86, 0.8) 52%, rgba(56, 86, 98, 0.8) 75%), url(./images/formas-digitales-fondo-cta-distribuidores.webp);
  background-size: cover;
  background-position: center;
  height: 300px;
}

.error_code {
  font-size: 200px;
}

.bg-gradient-text {
  background: rgb(2, 40, 88);
  background: -moz-linear-gradient(-45deg, rgba(2, 40, 88, 1) 0%, rgba(78, 165, 114, 1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(2, 40, 88, 1) 0%, rgba(78, 165, 114, 1) 100%);
  background: linear-gradient(135deg, rgba(2, 40, 88, 1) 0%, rgba(78, 165, 114, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#022858', endColorstr='#4ea572', GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.partner_logo {
  width: auto;
  height: 100px;
}

.socialmedia-bar {
  position: fixed !important;
  margin: auto !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 25px;
  z-index: 9999 !important;
  color: #fff !important;
  width: auto !important;
  height: 235px !important;
  padding: 1rem;
  background-color: rgba(69, 90, 100, 0.75);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border-radius: 8px;
  backdrop-filter: blur(10px);
}

.uk-light .facebook a,
.uk-light .twitter a,
.uk-light .youtube a,
.uk-light .linkedin a,
.uk-light .instagram a,
.uk-light .teamviewer a {
  color: #fff !important;
}

.facebook:hover a {
  color: var(--facebook-color) !important;
}

.twitter:hover a {
  color: var(--twitter-color) !important;
}

.youtube:hover a {
  color: var(--youtube-color) !important;
}

.linkedin:hover a {
  color: var(--linkedin-color) !important;
}

.instagram:hover a {
  color: var(--instagram-color) !important;
}

.teamviewer:hover a {
  color: var(--twitter-color) !important;
}

.uk-transform-origin-center-left {
  color: #000 !important;
  background-color: #fff !important;
  font-size: .8rem !important;
  box-shadow: 0 2px 10px rgba(55, 71, 79, .75);
}

.uk-link-toggle:focus .uk-link,
.uk-link-toggle:hover .uk-link,
.uk-link:hover,
a:hover {
  text-decoration: none !important;
}

.grecaptcha-badge {
  /*visibility: hidden;*/
}


.uk-margin-homeslide-logo {
  margin-left: 35% !important;
  margin-top: -100px !important;
}

.logoBuenFin {
  width: 50%;
}



/* (869x412) */
@media (max-width: 869px) {
  .uk-margin-homeslide-logo {
    margin-left: 30% !important;
    margin-top: 120px !important;
  }

  .logoBuenFin {
    width: 100px !important;
  }
}


/* (1366x768) WXGA Display */
@media (min-width: 870px) and (max-width: 1366px){
  .uk-margin-homeslide-logo {
    margin-left: 25% !important;
  }
  .logoBuenFin {
    width: 280px !important;
  }
}